import request from '../until/request.js'

// 发表问答交流
export const MgetUsers = () => {
  return request.get('/admin/MgetUsers')
}

// 封禁用户
export const MbanUser = data => {
  return request.post('/admin/MbanUser', data)
}

// 解封用户
export const MunDo = data => {
  return request.post('/admin/MunDo', data)
}

// 删除用户
export const MDelUser = data => {
  return request.post('/admin/MDelUser', data)
}

// 获取二手信息
export const MgetTrade = data => {
  return request.get('/admin/MgetTrade')
}

// 获取二手详情信息
export const MgetDetailTrade = data => {
  return request.post('/admin/MgetDetailTrade', data)
}

// 删除二手信息
export const MdelTrade = data => {
  return request.post('/admin/MdelTrade', data)
}

// 获取交流问答
export const MgetQue = data => {
  return request.get('/admin/MgetQue')
}

// 获取交流问答详情信息
export const MgetDetailQue = data => {
  return request.post('/admin/MgetDetailQue', data)
}
// 删除交流问答信息
export const MdelQue = data => {
  return request.post('/admin/MdelQue', data)
}

// 获取反馈列表
export const MgetFeed = data => {
  return request.get('/admin/MgetFeed')
}

// 获取反馈详情
export const MgetDetailFeed = data => {
  return request.post('/admin/MgetDetailFeed', data)
}
// 删除反馈
export const MdelFeed = data => {
  return request.post('/admin/MdelFeed', data)
}

// 获取所有用户
export const MgetAllUsers = data => {
  return request.get('/admin/MgetAllUsers')
}

// 设置管理
export const MsetManager = data => {
  return request.post('/admin/MsetManager', data)
}

// 取消管理
export const MunManger = data => {
  return request.post('/admin//MunManger', data)
}
